exports.getProductOptions = (productSockQuantity, isOutOfStock = false) => {
  const maxAllowedQuantity = 3000;
  let maxQuantity;

  if (productSockQuantity > maxAllowedQuantity || isOutOfStock) {
    maxQuantity = maxAllowedQuantity;
  } else {
    maxQuantity = productSockQuantity;
  }

  const options = [];
  for (let i = 50; i <= maxQuantity; i = i + 25) {
    options.push(i);
  }
  return options;
};
