import { apiClient } from "./config/serviceConfiguration";

export default {
  completeUserRegistration(userRegistrationData) {
    return apiClient.patch("/users/me", userRegistrationData);
  },
  updateMe(userData) {
    return apiClient.put("/users/me", userData);
  },
  getMyPendingOrders() {
    return apiClient.get("/users/me/pending-orders");
  },
  getMyOrders() {
    return apiClient.get("/users/me/orders");
  },
  getMyAddresses() {
    return apiClient.get("/users/me/addresses");
  },
  getAllUser() {
    return apiClient.get("/users");
  },
  getMe() {
    return apiClient.get("users/me");
  },
  getUserAddresses(userId) {
    return apiClient.get("/users/" + userId + "/addresses");
  },
  createUserAddress(address) {
    return apiClient.post("/users/me/addresses", address);
  },
  updateUserAddress(addressId, address) {
    return apiClient.put("/users/me/addresses/" + addressId, address);
  },
  deleteUserAddress(addressId) {
    return apiClient.delete("/users/me/addresses/" + addressId);
  },
  createUserOrder(order) {
    return apiClient.post("/users/me/orders", order);
  },
  setDefaultUserDeliveryAddress(payload) {
    return apiClient.post("/users/me/addresses/set-default", payload);
  },
  deleteMyAccount() {
    return apiClient.delete("/users/me");
  },
};
