<template>
  <div
    class="fruit"
    :class="isInTheCart ? 'fruit--added' : ''"
    v-show="showProduct()"
  >
    <!--    :title="`Click para ver más información sobre ${product.name}`"-->
    <div class="fruit-img-container">
      <picture>
        <!-- <source
          srcset="@/assets/img/catalog/nuts/almonds.webp"
          type="image/webp"
        /> -->
        <img
          v-if="product.photo"
          class="fruit-img"
          :class="isOutOfStock ? 'fruit--out-of-stock' : ''"
          :src="product.photo?.url"
          alt="product picture"
          draggable="false"
        />
        <img
          v-else
          class="fruit-img"
          src="@/assets/img/icons/picture.svg"
          alt="no image"
          draggable="false"
        />
      </picture>
    </div>

    <div class="fruit-content">
      <div class="fruit-tags">
        <span
          v-for="property in product.properties"
          :key="property.name"
          class="tag"
          :style="`background-color: ${property.color}`"
        >
          {{ property.name }}
        </span>
      </div>

      <p class="fruit-title">{{ Transform.capitalizeName(product.name) }}</p>

      <ul class="fruit-attributes">
        <li>
          <VueMultiselect
            :allow-empty="false"
            v-model="item.quantity"
            :options="productOptions"
            :showLabels="false"
            :searchable="false"
            placeholder="Selec. cantidad"
            :custom-label="valueWithGrams"
            :showNoResults="false"
          >
          </VueMultiselect>
        </li>
        <li class="fruit-attribute">
          <span>
            $<strong>{{
              (item.quantity * product.price).toLocaleString()
            }}</strong>
          </span>

          <div v-if="!isOutOfStock">
            <button
              v-if="!isInTheCart"
              class="fruit-action fruit-action--add"
              @click="addToCart"
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.5 21C11.3284 21 12 20.3284 12 19.5C12 18.6716 11.3284 18 10.5 18C9.67157 18 9 18.6716 9 19.5C9 20.3284 9.67157 21 10.5 21Z"
                />
                <path
                  d="M17.5 21C18.3284 21 19 20.3284 19 19.5C19 18.6716 18.3284 18 17.5 18C16.6716 18 16 18.6716 16 19.5C16 20.3284 16.6716 21 17.5 21Z"
                />
                <path
                  d="M21 7.00001H7.334L6.18 4.23001C6.02851 3.86515 5.77211 3.55348 5.4433 3.33451C5.11448 3.11553 4.72806 2.99911 4.333 3.00001H2V5.00001H4.334L9.077 16.385C9.232 16.757 9.597 17 10 17H18C18.417 17 18.79 16.741 18.937 16.352L21.937 8.35201C21.9932 8.2006 22.012 8.03786 21.9918 7.87763C21.9716 7.7174 21.913 7.56441 21.8211 7.43167C21.7291 7.29893 21.6064 7.19037 21.4634 7.11522C21.3205 7.04006 21.1615 7.00054 21 7.00001ZM17 13H15V15H13V13H11V11H13V9.00001H15V11H17V13Z"
                />
              </svg>
              <span>Agregar</span>
            </button>
            <button
              v-else
              class="fruit-action fruit-action--remove"
              @click="removeFromCart"
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M21.822 7.431C21.73 7.29808 21.6072 7.18943 21.464 7.11436C21.3209 7.0393 21.1616 7.00006 21 7H7.333L6.179 4.23C6.02769 3.86523 5.77147 3.55359 5.44282 3.33462C5.11418 3.11564 4.72791 2.99918 4.333 3H2V5H4.333L9.077 16.385C9.15299 16.5672 9.28118 16.7228 9.44542 16.8322C9.60967 16.9416 9.80263 17 10 17H18C18.417 17 18.79 16.741 18.937 16.352L21.937 8.352C21.9937 8.20063 22.0129 8.03776 21.9928 7.87735C21.9728 7.71695 21.9142 7.56379 21.822 7.431Z"
                />
                <path
                  d="M10.5 21C11.3284 21 12 20.3284 12 19.5C12 18.6716 11.3284 18 10.5 18C9.67157 18 9 18.6716 9 19.5C9 20.3284 9.67157 21 10.5 21Z"
                />
                <path
                  d="M17.5 21C18.3284 21 19 20.3284 19 19.5C19 18.6716 18.3284 18 17.5 18C16.6716 18 16 18.6716 16 19.5C16 20.3284 16.6716 21 17.5 21Z"
                />
              </svg>
              <span>Quitar</span>
            </button>
          </div>
          <div v-else class="out-of-stock-container">
            <span>Sin stock</span>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import Transform from "@/features/transform";
import VueMultiselect from "vue-multiselect";
import { normalizedString } from "@/features/stringUtils";
import { getProductOptions } from "@/features/productOptions";

export default {
  components: {
    VueMultiselect,
  },
  data() {
    return {
      item: {
        product: this.product,
        quantity: 100,
      },
    };
  },
  props: {
    product: Object,
    filter: String,
  },
  created() {
    if (this.isInTheCart) {
      this.item = this.itemInCart;
    }
  },
  methods: {
    addToCart() {
      this.$store.dispatch("order/addToCart", this.item);
    },
    removeFromCart() {
      this.$store.dispatch("order/removeFromCart", this.item);
    },
    showProduct() {
      const filter = this.$store.state.product.searchFilter;
      const filterEmpty = filter === "";
      const filterMatchesProductName = normalizedString(
        this.product.name
      ).includes(normalizedString(filter));

      // this code in case we use filter by properties
      // const filterMatchesPropertyName =
      //   this.product.properties &&
      //   this.product.properties.some((property) =>
      //     property.name.toLocaleLowerCase().includes(filter.toLocaleLowerCase())
      //   );

      return filterEmpty || filterMatchesProductName;
    },
    valueWithGrams(value) {
      return `${value} gr.`;
    },
  },
  computed: {
    Transform() {
      return Transform;
    },
    isInTheCart() {
      return this.$store.state.order.newOrder.items.some(
        (item) => item.product._id === this.product._id
      );
    },
    itemInCart() {
      return this.$store.state.order.newOrder.items.find(
        (item) => item.product._id === this.product._id
      );
    },
    isOutOfStock() {
      return this.product.stockQuantity <= this.product.outOfStockTrigger;
    },
    productOptions() {
      return getProductOptions(this.product.stockQuantity, this.isOutOfStock);
    },
  },
};
</script>

<style>
.fruit {
  box-shadow: 0 4px 6px rgba(32, 33, 36, 0.16);
  border-radius: 11px;
  transition: all 0.4s;
  text-decoration: none;
  color: #495057;
  background-color: #fff;
  border: 1px solid #eee;
  /* cursor: pointer; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.fruit--added {
  background-color: #c3fae8;
}

.fruit--out-of-stock {
  filter: grayscale(1);
}

.fruit-content {
  padding: 3.2rem 4rem 4rem;
}

.fruit-tags {
  margin-bottom: 1.2rem;
  display: flex;
  gap: 0.4rem;
}

.tag {
  display: inline-block;
  padding: 0.4rem 0.8rem;
  font-size: 1.2rem;
  text-transform: uppercase;
  color: #333;
  border-radius: 100px;
  font-weight: 600;
  background-color: #eee;
  height: min-content;
}

.fruit-title {
  font-size: 2rem;
  color: #495057;
  font-weight: 500;
  margin-bottom: 3.2rem;
  text-align: center;
  line-height: 3rem;
}

.fruit-attributes {
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.fruit-attribute {
  font-size: 1.8rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1.6rem;
  position: relative;
}

.fruit-img {
  width: 100%;
  border-bottom: 1px solid #eee;
  transition: all 0.3s;
  aspect-ratio: 1 / 1;
}

.fruit--added .fruit-img {
  border-bottom: 1px solid #fff;
}

.fruit-action {
  font-family: inherit;
  font-weight: 600;
  font-size: 1.4rem;
  padding: 7px 12px;
  border-radius: 9px;
  cursor: pointer;
  transition: all 0.3s;
  box-shadow: 0 3px 6px rgba(32, 33, 36, 0.28);
  display: flex;
  align-items: center;
  gap: 6px;
}

.fruit-action:disabled {
  cursor: not-allowed;
  background-color: #495057;
}

.fruit-action:focus {
  outline: rgba(46, 176, 134, 0.2) 2px solid;
}

.fruit-action--add {
  color: #495057;
  background-color: #c3fae8;
  fill: #495057;
}

.fruit-action--add:hover {
  color: #e6fcf5;
  background-color: #087f5b;
  fill: #e6fcf5;
}

.fruit-action--remove {
  color: #f03e3e;
  background-color: #ffe3e3;
  fill: #f03e3e;
}

.fruit-action--remove:hover {
  color: #ffe3e3;
  background-color: #f03e3e;
  fill: #ffe3e3;
}

.out-of-stock-container {
  height: 3.8rem;
  display: flex;
  align-items: center;
}

/* Below 544PX (Phones) */
@media (max-width: 34em) {
  .fruit-content {
    padding: 1.8rem 1.8rem 1.8rem 1rem;
  }

  .fruit {
    box-shadow: 0 4px 6px rgba(32, 33, 36, 0.04);
  }
}

/* Below 704PX (Smaller Tablets) */
@media (max-width: 44em) {
  .fruit-img {
    border-bottom: none;
  }

  .fruit-img-container {
    align-self: center;
  }

  .fruit--added .fruit-img {
    border-bottom: none;
  }
}
</style>
